import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import config from "../coreFIles/config";
import Header from '../directives/header'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import { } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessfwMember')) ? [] : JSON.parse(Cookies.get('loginSuccessfwMember'));
const Home = () => {	
	useEffect(() => {
		if (!loginData || loginData == '') {
		window.location.href = `${config.baseUrl}/login`;
	  }	
	
	},[])	
	const back = async () => {
	  window.location.href = `${config.baseUrl}`;
   }  
		
	return(
		<>
			<Header />			
			<div className="wrapper-content-banner">
					<div className="container">					
					<div className="registration checkout-page">
					<div className="checkout-order">
						<h2 id="order_review_heading">Thank you. Your order has been received.</h2>
					</div>
					<div className="col-12 mt-20 text-center">
						<button id="pay-button" onClick={back}> Back to home</button>
					</div>											
				</div>
				</div>
				</div>	
			<Footer />
		</>
  	)
}

export default Home;
