import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import { InputMask } from '@react-input/mask';
import { ForgotPasswordAction } from '../Action/action';

const loginData = (!Cookies.get('loginSuccessfwMember')) ? [] : JSON.parse(Cookies.get('loginSuccessfwMember'));
const ForgetPassword = () => {
	const [form, setForm] = useState({ email: '' })
	const [validationError, setvalidationError] = useState({});
	const [isLoading, setisLoading] = useState(false);
	useEffect(() => {
		
	},[])
	
	const inputHandler = (e) => {
        const { name, value, id } = e.target
        setForm((old) => {
            return { ...old, [name]: value }
        })

        if (value != '') {
            setvalidationError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }

    function validate() {
        let emailError = "";

        if (form.email === '') {
            emailError = "Email is required."
        }
        if (emailError) {
            setvalidationError({
                emailError
            })
            return false
        } else {
            return true
        }
    }

    const SubmitForm = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {

        }
        else {
            let res = await ForgotPasswordAction(form);
            if (res.success) {
                setForm((old) => {
                    return { ...old, ['email']: '' }
                })
                toast.success(res.msg);
                setTimeout(() => {
                    window.location.href = `${config.baseUrl}login`;
                }, 3000);
            } else {
                toast.error(res.msg);
            }
        }
    }
	return(
		<>
		<Header />	
		 <Toaster />	
		<div className="wrapper-content-banner">
			<div className="container">
				<div className="wrapper-content">
					<h1>Forgot Password</h1>
				</div>
				<div classNameName="background-color"></div>
				<div className="registration">				
					<div className="row">
						<div className="col-md-12">
							<div className="registration-heading">
								<h2>Enter your email to reset your password.</h2>
								<div className="form-section">
									<div className="row">
										<div className="form-group col-8">
											<input type="text" className={`form-control ${validationError.emailError ? 'is-invalid' : ''}`} id="email" name="email" placeholder="Enter email address" autocomplete="off" onChange={inputHandler} />
											
										</div>
									</div>
									<Link to={config.baseUrl + 'login'} className="forgot"> Click here to login</Link>									
									<div className="login-button">
										<button className="login-btn" onClick={SubmitForm}>Submit</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Footer />
		</>
		)
}

export default ForgetPassword;
