import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { InputMask } from '@react-input/mask';
import { getUserProfileAction, UpdateUserPasswordAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessfwMember')) ? [] : JSON.parse(Cookies.get('loginSuccessfwMember'));
const isLoginDataEmpty = Object.keys(loginData).length === 0;
const ForgetPassword = () => {
	const [form, setForm] 	= useState({ email:loginData?.email, curpassword: '', password: '', cpassword: '' });	
	const [isLoading, setisLoading] = useState(false);
	const [UserProfile, setUserProfile] = useState({});
	const [spinpwLoader, setSpinpwLoader] 						= useState(0);
	const [pwvalidationError, setpwvalidationError] 			= useState({});
	const [showInfoBox, setShowInfoBox] 						= useState(false);
	const [passwordValidation, setPasswordValidation] = useState({
		minLength: false,
		hasUppercase: false,		
		hasSymbol: false,
		hasUppsymb: false,
	  });
	useEffect(() => {		
		if (isLoginDataEmpty) {
			window.location.href = `login`;
		  }	
	   getUserProfileAPI();
		
	},[])
	
	const getValidationStyle = (isValid) => ({
	  color: isValid ? "green" : "red"	  
	});  
	
	
	const getUserProfileAPI = async () => {
		let res = await getUserProfileAction({user_id:loginData?.id});
		if (res.success) {
			let data = res.data;			
			setUserProfile(data);
		}
		else
		{
			setUserProfile([]);							
		}
	}
	const inputPwHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
    const handlePasswordChange  = async (e) => {
		const newPassword = e.target.value;
		setForm((old) => {
				return { ...old, 'password': newPassword };
				});
		setPasswordValidation(validatePassword(newPassword));
	  };
	
	const validatePassword = (password) => {
		const minLength = password.length >= 6;		
		const hasUpper = /[A-Z]/.test(password);
		const hasSymb = /[\W_]/.test(password);
		const isValid = (hasUpper || hasSymb);		
		const hasUppercase = isValid;
		const hasSymbol = isValid;
		const hasUppsymb = isValid;		
		return {
		  minLength,		 
		  hasUppercase,
		  hasSymbol,
		  hasUppsymb,
		};
	  };	
	
function validatepwd() {	   
		let curpasswordError = "";
		let passwordError = "";		
		let cpasswordError = "";	
		
		if (form.curpassword === '') {
		  curpasswordError = "Current password is required."
		}
		if (form.password === '') {
			passwordError = "New password is required."
		}		
		if (form.cpassword === '') {
			cpasswordError = "Confirm password required."
		}
		
		if(form.password !='')
		{
			validatePassword(form.password);
			
			if (!(passwordValidation.minLength && passwordValidation.hasUppsymb)) {
				passwordError = "Please ensure your password meets all the requirements.";
			}
			if (form.cpassword != form.password) {
				cpasswordError = "Confirm password required."
			}
		}
			
		if (curpasswordError || passwordError || cpasswordError) {
			setpwvalidationError({ curpasswordError, passwordError, cpasswordError	})
			return false
		} else {
			setpwvalidationError({ curpasswordError, passwordError, cpasswordError })
			return true
		}
	}	
	const UpdateUserPassword = async (e) => {
		e.preventDefault()
		const isValid = validatepwd();
		if (!isValid) {
		}else {
			setSpinpwLoader(1);
			let res = await UpdateUserPasswordAction(form);
			if (res.success) {
				toast.success(res.msg);				
				setSpinpwLoader(0);	
				setTimeout(() => {
						window.location.href = `${config.baseUrl}`;
					}, 2000);
						
			} else {
				toast.error(res.msg);
				setSpinpwLoader(0);
			}
		}
	}
	return(
		<>
		<Header />	
		 <Toaster />	
		<div className="wrapper-content-banner">
				<div id="cartsection">									
					<div className="container">					
						<div id="fixedcart" className="cart-inner-block">
						<div className="company-name">
						  <h2 id="minimum_order_total" className="error">Please change your account password before proceeding.</h2>
						</div>
						<div className="registration-heading mt-3">	
										<h2>Change Password</h2>						
										<div className="form-section section-register">
											<div className="form-row"> 
											<div className="form-group col-md-4">
												<label for="curpassword">Current Password *</label>
												<input type="password" className={`form-control ${pwvalidationError.curpasswordError ? 'is-invalid' : ''}`} id="curpassword" placeholder="Enter Current Password" name="curpassword" onChange={inputPwHandler} value={form.curpassword} />
											</div>
											<div className="form-group col-md-4">
												<label for="password">New Password *</label>
												<input type="password" className={`form-control ${pwvalidationError.passwordError ? 'is-invalid' : ''}`} id="password" placeholder="Enter New Password" name="password" onChange={handlePasswordChange} value={form.password}  onFocus={() => setShowInfoBox(true)} onBlur={() => setShowInfoBox(false)} autocomplete="off" />
												 {showInfoBox && (
												<div className="validationBoxStyle">
												 <ul style={{ margin: 0, paddingLeft: "15px", listStyle: "none" }}>
													<li style={getValidationStyle(passwordValidation.minLength)}>
													  {passwordValidation.minLength
														? "✓ At least 6 characters"
														: "✗ At least 6 characters"}
													</li>
													<li style={getValidationStyle(passwordValidation.hasUppsymb)}>
													  {passwordValidation.hasUppsymb
														? "✓ Uppercase letter (A-Z) OR A symbol (e.g. @, $, %, !)"
														: "✗ Uppercase letter (A-Z) OR A symbol (e.g. @, $, %, !)"}
													</li>
												  </ul>
											  </div>
												)}
											</div>
											<div className="form-group col-md-4">
												<label for="cpassword">Confirm Password *</label>
												<input type="password" className={`form-control ${pwvalidationError.cpasswordError ? 'is-invalid' : ''}`} id="cpassword" placeholder="Enter Confirm Password" name="cpassword" onChange={inputPwHandler} value={form.cpassword} />
											</div>											
										</div>
										</div>
										<div className="login-button text-right">
											<button className="login-btn" onClick={UpdateUserPassword} disabled={spinpwLoader==1 ? true : ''}>{spinpwLoader==0 ? 'UPDATE PASSWORD' : 'UPDATING...' }</button>
										</div>
									</div>
					</div>
				</div>
			</div>
		</div>	
		<Footer />
		</>
		)
}

export default ForgetPassword;
