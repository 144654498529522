import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../directives/header'
import Footer from '../directives/footer'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Cookies from 'js-cookie';
import { ResetPasswordAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessfwMember')) ? [] : JSON.parse(Cookies.get('loginSuccessfwMember'));
const isLoginDataEmpty = Object.keys(loginData).length === 0;
const ResetPassword = () => {

    let { token } = useParams();
    const [cartcount, setcartcount] = useState(0);
    const [form, setForm] = useState({ password: '', confirm_password: '', token : token })
	const [isLoading, setisLoading] = useState(false);
	const [UserProfile, setUserProfile] = useState({});
	const [spinpwLoader, setSpinpwLoader] 						= useState(0);
	const [pwvalidationError, setpwvalidationError] 			= useState({});
	const [showInfoBox, setShowInfoBox] 						= useState(false);
	const [passwordValidation, setPasswordValidation] = useState({
		minLength: false,
		hasUppercase: false,		
		hasSymbol: false,
		hasUppsymb: false,
	  });
	//~ useEffect(() => {		
		//~ if (isLoginDataEmpty) {
			//~ window.location.href = `login`;
		  //~ }	
		
	//~ },[])
	
	const getValidationStyle = (isValid) => ({
	  color: isValid ? "green" : "red"	  
	});  
	
	
	const inputPwHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
    const handlePasswordChange  = async (e) => {
		const newPassword = e.target.value;
		setForm((old) => {
				return { ...old, 'password': newPassword };
				});
		setPasswordValidation(validatePassword(newPassword));
	  };
	
	const validatePassword = (password) => {
		const minLength = password.length >= 6;		
		const hasUpper = /[A-Z]/.test(password);
		const hasSymb = /[\W_]/.test(password);
		const isValid = (hasUpper || hasSymb);		
		const hasUppercase = isValid;
		const hasSymbol = isValid;
		const hasUppsymb = isValid;		
		return {
		  minLength,		 
		  hasUppercase,
		  hasSymbol,
		  hasUppsymb,
		};
	  };	
	
function validatepwd() {	   
		let passwordError = "";		
		let confirm_passwordError = "";	
		
		if (form.password === '') {
			passwordError = "New password is required."
		}		
		if (form.confirm_password === '') {
			confirm_passwordError = "Confirm password required."
		}
		
		if(form.password !='')
		{
			validatePassword(form.password);
			
			if (!(passwordValidation.minLength && passwordValidation.hasUppsymb)) {
				passwordError = "Please ensure your password meets all the requirements.";
			}
			if (form.confirm_password != form.password) {
				confirm_passwordError = "Confirm password required."
			}
		}
			
		if (passwordError || confirm_passwordError) {
			setpwvalidationError({ passwordError, confirm_passwordError	})
			return false
		} else {
			setpwvalidationError({ passwordError, confirm_passwordError })
			return true
		}
	}	
	const ResetPassword = async (e) => {
		e.preventDefault()
		const isValid = validatepwd();
		if (!isValid) {
		}else {
			setSpinpwLoader(1);
			let res = await ResetPasswordAction(form);
			if (res.success) {
				toast.success(res.msg);				
				setSpinpwLoader(0);	
				setTimeout(() => {
						window.location.href = `${config.baseUrl}`;
					}, 2000);
						
			} else {
				toast.error(res.msg);
				setSpinpwLoader(0);
			}
		}
	}
	return(
		<>
		<Header />	
		 <Toaster />	
		<div className="wrapper-content-banner">
			<div className="container">
				<div className="wrapper-content">
					<h1>Reset Password</h1>
				</div>
				<div classNameName="background-color"></div>
				<div className="registration">				
					<div className="row">
						<div className="col-md-12">
							<div className="registration-heading">
								<div className="form-section">
										
								<div className="form-row">
									<div className="form-group col-md-6" style={{ position: "relative", marginBottom: "20px" }}>
										<label for="password">Password *</label>
										<input type="password" className={`form-control ${pwvalidationError.passwordError ? 'is-invalid' : ''}`} id="password" placeholder="Enter Password" name="password" onChange={handlePasswordChange} value={form.password} onFocus={() => setShowInfoBox(true)} onBlur={() => setShowInfoBox(false)} autocomplete="off"/>
										 {showInfoBox && (
											<div className="validationBoxStyle">
											  <ul style={{ margin: 0, paddingLeft: "15px", listStyle: "none" }}>
												<li style={getValidationStyle(passwordValidation.minLength)}>
												  {passwordValidation.minLength
													? "✓ At least 6 characters"
													: "✗ At least 6 characters"}
												</li>
												<li style={getValidationStyle(passwordValidation.hasUppsymb)}>
												  {passwordValidation.hasUppsymb
													? "✓ Uppercase letter (A-Z) OR A symbol (e.g. @, $, %, !)"
													: "✗ Uppercase letter (A-Z) OR A symbol (e.g. @, $, %, !)"}
												</li>
											  </ul>
											</div>
										)}
									</div>
									<div className="form-group col-md-6">
										<label for="password">Confirm Password *</label>
										<input type="password" className={`form-control ${pwvalidationError.confirm_passwordError ? 'is-invalid' : ''}`} id="confirm_password" name="confirm_password" placeholder="Confirm Password" onChange={inputPwHandler} value={form.confirm_password} />
									</div>
								</div>
							</div>

							<div className="login-button text-right">
								<button className="login-btn" onClick={ResetPassword} disabled={spinpwLoader==1 ? true : ''}>{spinpwLoader==0 ? 'Submit' : 'UPDATING...' }</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>	
		</div>	
		<Footer />
		</>
		)
}
export default ResetPassword;
