import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import { InputMask } from '@react-input/mask';
import { LoginAction, getindustrylistAction, getMonthlySpendlistAction, getSearchSuburbAction, RegisterUserAction, getGloabalSettingsAction } from '../Action/action';

const loginData = (!Cookies.get('loginSuccessfwMember')) ? [] : JSON.parse(Cookies.get('loginSuccessfwMember'));
const Login = () => {
	const [loginform, setloginForm] 			= useState({ email: '', password: '' });	
	const [validationError, setvalidationError] = useState({});
	const [regvalidationError, setregvalidationError] = useState({});
	const [reg1validationError, setreg1validationError] = useState({});
	const [passwordShown, setPasswordShown] = useState(0);
	const [industry, setindustry] 			= useState([]);
	const [monthly_spend, setmonthly_spend] = useState([]);
	const [SuburbsearchResults, setSuburbsearchResults] 	= useState([]);
	const [showcod, setshowcod] 			= useState(0);
	const [showstep2, setshowstep2] 		= useState(0);
	const [chknewsletter, setchknewsletter] = useState(0);	
	const [spinLoader, setSpinLoader] = useState(0);
	const [suburb, setsuburb] 				= useState('');
	const [postcode, setpostcode] 			= useState('');
	const [showInfoBox, setShowInfoBox] = useState(false);
	const [passwordValidation, setPasswordValidation] = useState({
		minLength: false,
		hasUppercase: false,		
		hasSymbol: false,
		hasUppsymb: false,
	  });
	const [regform, setregForm] = useState({ first_name: '', last_name: '', email: '', accounts_email_address: '', send_emails_to_both_emails: '0', password: '', cpassword: '', phone: '', company: '', industry: '', address: '', suburb: '', state: '', postcode: '', abn: '', monthly_spend: '', is_cod: '1', product_range: 0, minimum_order_total: '0', free_shipping: '1', minimum_amout_for_free_shipping: '0', tiered_group: '1', is_active: 0, override_global_free_shipping: 0, terms:0, newsletter:0, country:'AU' });
	useEffect(() => {
		getindustryAPI();
		getmonthly_spendAPI();
		getGloabalSettingsAPI();
		
	},[])
	
	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	  };
	
	const inputLoginHandler = async (e) => {
		const { name, value } = e.target
		setloginForm((old) => {
			return { ...old, [name]: value }
		})
	}
	const getindustryAPI = async () => {
		let res = await getindustrylistAction();
		if (res.success) {
			let data = res.data;			
			setindustry(data);	
		}
		else
		{
			setindustry([]);	
		}
	}
	const getGloabalSettingsAPI = async () => {
		let res = await getGloabalSettingsAction();
		if (res.success) {
			let data = res.data;			
			setregForm((old) => {
			return { ...old, 'minimum_amout_for_free_shipping': data };
			});	
		}
	}
	const getmonthly_spendAPI = async () => {
		let res = await getMonthlySpendlistAction();
		if (res.success) {
			let data = res.data;			
			setmonthly_spend(data);	
		}
		else
		{
			setmonthly_spend([]);	
		}
	}
	function validate() {
		let emailError = "";
		let passwordError = "";
	
		if (loginform.email === '') {
			emailError = "Email is required."
		}
		if (loginform.password === '') {
			passwordError = "Password is required."
		}
		
		if (emailError || passwordError) {
			setvalidationError({
				emailError, passwordError
			})
			
			return false
		} else {
			return true
		}
	}
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {
	
		}
		else {
			let res = await LoginAction(loginform);
			if (res.success) {
				toast.success(res.msg);
				Cookies.set('loginSuccessfwMember', JSON.stringify(res.data));
				if(res.force_user_to_change_pwd==1){
					setTimeout(() => {
						window.location.href = `${config.baseUrl}change-password`;
					}, 2000);
				}else{
					setTimeout(() => {
						window.location.href = `${config.baseUrl}`;
					}, 2000);
				}
			} else {
				toast.error(res.msg);
			}
		}
	}		
	const inputRegHandler = async (e) => {
		const { name, value } = e.target
		setregForm((old) => {
			return { ...old, [name]: value }
		})
	}
   const handlePasswordChange  = async (e) => {
    const newPassword = e.target.value;
    setregForm((old) => {
			return { ...old, 'password': newPassword };
			});
    setPasswordValidation(validatePassword(newPassword));
  };
	const validatePassword = (password) => {
		const minLength = password.length >= 6;		
		const hasUpper = /[A-Z]/.test(password);
		const hasSymb = /[\W_]/.test(password);
		const isValid = (hasUpper || hasSymb);		
		const hasUppercase = isValid;
		const hasSymbol = isValid;
		const hasUppsymb = isValid;		
		return {
		  minLength,		 
		  hasUppercase,
		  hasSymbol,
		  hasUppsymb,
		};
	  };	
	const getValidationStyle = (isValid) => ({
	  color: isValid ? "green" : "red"	  
	});
	
	const inputmonthly_spendHandler = async (e) => {
		const { name, value } = e.target
		setregForm((old) => {
			return { ...old, [name]: value }
		})
		if(value ==='')
		{
			setshowcod(0)
		}
		else if(value !='1')
		{
			setshowcod(1)
		}
		else
		{
			setshowcod(0)
		}
	}
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
		  e.preventDefault();
		}
	  }; 
	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			setpostcode('');
			setregForm((old) => {
			return { ...old, 'postcode': '' };
			});
			setregForm((old) => {
				return { ...old, 'state': '' };
			});
			let res = await getSearchSuburbAction({str:str});
			setSuburbsearchResults(res.data);
		}
		catch (error) {
			console.error(error);
		}
	};	   
    const handleSelectSuburb = (row) => {	 
		setsuburb(row.suburb);
		setpostcode(row.postcode);
		setregForm((old) => {
			return { ...old, 'suburb': row.suburb };
		});
		setregForm((old) => {
			return { ...old, 'postcode': row.postcode };
		});
		setregForm((old) => {
			return { ...old, 'state': row.state };
		});
		
		
		setSuburbsearchResults({});
	};	
	function Regstep1validate() {	   
		let first_nameError = "";
		let last_nameError = "";
		let emailError = "";
		let passwordError = "";
		let cpasswordError = "";
		let phoneError = "";
		let companyError = "";
		let industryError = "";		
		//const phoneRegex = /^04\d{0,8}$/;
		if (regform.first_name === '') {
		  first_nameError = "First name is required."
		}
		if (regform.last_name === '') {
			last_nameError = "Last name is required."
		}
		if (regform.email === '') {
			emailError = "Email is required."
		}
		if (regform.password === '') {
			passwordError = "Password is required."
		}
		if (regform.cpassword === '') {
			cpasswordError = "Password is required."
		}
		if (regform.phone === '') {
			phoneError = "Phone is required."
		}
		if (regform.company === '') {
			companyError = "Company name is required."
		}
		if (regform.industry === '') {
			industryError = "Industry is required."
		}				
		if(regform.email != '')	{
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if(emailRegex.test(regform.email) === false)
			{
				emailError = "Please enter valid email."
			}
		}	
		if (regform.password != '') {
			 validatePassword(regform.password);
			
			if (!(passwordValidation.minLength && passwordValidation.hasUppsymb)) {
				passwordError = "Please ensure your password meets all the requirements.";
			}
			if (regform.cpassword != regform.password) {
				cpasswordError = "Password is required."
			}
		}
					
		if (first_nameError || last_nameError || emailError || passwordError || cpasswordError || phoneError || companyError || industryError) {
			setreg1validationError({
			  first_nameError, last_nameError, emailError, passwordError, cpasswordError, phoneError, companyError, industryError
			})
			return false
		} else {
			setreg1validationError({
			  first_nameError, last_nameError, emailError, passwordError, cpasswordError, phoneError, companyError, industryError
			})
			return true
		}
	}
	
	 const handletermsCheckboxChange = (e) => {
		 setregForm((old) => {
			return { ...old, 'terms': e.target.checked };
		});		 
	  }; 
	  
	  const handlenewsletterCheckboxChange = (e) => {
		 setregForm((old) => {
			return { ...old, 'newsletter': e.target.checked };
		});	 
	  };
	 
	  const handleinvoiceCheckboxChange = (e) => {
		if(e.target.checked==true){
		 setregForm((old) => {
			return { ...old, 'send_emails_to_both_emails': 1 };
		});	 
		}else{
			setregForm((old) => {
			return { ...old, 'send_emails_to_both_emails': 0 };
		});	 
		}
	  };
	 
	 const handleKeyDown = (e) => {
		//console.log(e.key);
        if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
            e.preventDefault();
        }
    }; 
    
     const handleKeyUp = (e) => {
		if (e.key === 'Enter') {
			SubmitForm(e);
		}
    };  
	
	function Regvalidate() {	
		const phoneRegex = /^04\d{0,8}$/;   
		let first_nameError = "";
		let last_nameError = "";
		let emailError = "";
		let passwordError = "";
		let cpasswordError = "";
		let phoneError = "";
		let companyError = "";
		let industryError = "";
		let addressError = "";
		let suburbError = "";
		let stateError = "";
		let postcodeError = "";
		let abnError = "";
		let monthly_spendError = "";
		let is_codError = "";
		let minimum_order_totalError = "";
		let free_shippingError = "";
		let minimum_amout_for_free_shippingError = "";
		let tiered_groupError = "";		
		let is_activeError = "";
		
		if (regform.first_name === '') {
		  first_nameError = "First name is required."
		}
		if (regform.last_name === '') {
			last_nameError = "Last name is required."
		}
		if (regform.email === '') {
			emailError = "Email is required."
		}
		if (regform.password === '') {
			passwordError = "Password is required."
		}
		if (regform.cpassword === '') {
			cpasswordError = "Password is required."
		}
		if (regform.phone === '') {
			phoneError = "Phone is required."
		}
		if (regform.company === '') {
			companyError = "Company name is required."
		}
		if (regform.industry === '') {
			industryError = "Industry is required."
		}
		if (regform.address === '') {
			addressError = "Address is required."
		}
		if (regform.suburb === '') {
			suburbError = "Suburb is required."
		}
		if (regform.state === '') {
			stateError = "State is required."
		}
		if (regform.postcode === '') {
			postcodeError = "Postcode is required."
		}
		if (regform.abn === '') {
			abnError = "ABN is required."
		}
		if (regform.monthly_spend === '') {
			monthly_spendError = "Monthly spend is required."
		}
		if (showcod==1 && regform.is_cod === '') {
			is_codError = "COD or a 30 day is required."
		}
				
		if(regform.email != '')
		{
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if(emailRegex.test(regform.email) === false)
			{
				emailError = "Please enter valid email."
			}
		}
		if (regform.password != '') {
			validatePassword(regform.password);			
			if (!(passwordValidation.minLength && passwordValidation.hasUppsymb)) {
				passwordError = "Please ensure your password meets all the requirements.";
			}
			if (regform.cpassword != regform.password) {
				cpasswordError = "Password is required."
			}
		}
		
		if (regform.terms == '0' || regform.terms == '') {
			toast.error("Please accept our terms and conditions");
		}
		//~ if (regform.phone != '') {
			 //~ if (!phoneRegex.test(regform.phone.replace(/\s+/g, ''))) {
				 //~ phoneError = "Phone is required."
				  //~ toast.error('Phone number must start with "04".');
			//~ }
		//~ }
					
		if (first_nameError || last_nameError || emailError || passwordError || cpasswordError || phoneError || companyError || industryError || addressError|| suburbError|| stateError|| postcodeError|| abnError|| monthly_spendError|| is_codError) {
			setregvalidationError({
			  first_nameError, last_nameError, emailError, passwordError, cpasswordError, phoneError, companyError, industryError, addressError, suburbError, stateError, postcodeError, abnError, monthly_spendError, is_codError
			})
			return false
		} else {
			setregvalidationError({
			  first_nameError, last_nameError, emailError, passwordError, cpasswordError, phoneError, companyError, industryError, addressError, suburbError, stateError, postcodeError, abnError, monthly_spendError, is_codError
			})
			return true
		}
	}
	
	const SubmitRegForm = async (e) => {
		e.preventDefault()
		const isValid = Regvalidate();
		if (!isValid) {

		}
		else {
			setSpinLoader(1);
			let res = await RegisterUserAction(regform);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}login`;
				}, 2000);
			} else {
				setSpinLoader(0);
				toast.error(res.msg);
			}
		}
	}
	
	const ShowNext = async (e) => {	
		setSpinLoader(1);
		e.preventDefault()
		const isValid = Regstep1validate();
		if (!isValid) {
			setSpinLoader(0);
		}
		else {	
			setTimeout(() => {
				setshowstep2(1);
				setSpinLoader(0);
			}, 200);
		}	
	}
	const ShowPrev = async (e) => {	
		setSpinLoader(1);		
		setTimeout(() => {
			setshowstep2(0);
			setSpinLoader(0);
		}, 200);	
	}
	
	return(
		<>
		<Header />	
		 <Toaster />	
		<div className="wrapper-content-banner">
			<div className="container">
				<div className="wrapper-content">
					<h1>LOGIN</h1>
				</div>
				<div classNameName="background-color"></div>
				<div className="registration">
				{showstep2 === 0 ?
					(<div className="row">
						<div className="col-md-5">
							<div className="registration-heading login-heading">
								<h2>LOGIN BELOW</h2>
								<div className="form-section">
									<div className="form-group col-12">
										<label for="exampleInputEmail1">Username or email address *</label>
										<input type="text" className={`form-control ${validationError.emailError ? 'is-invalid' : ''}`} id="email" name="email" placeholder="Enter email address" autocomplete="off" onChange={inputLoginHandler} />
										
									</div>
									<div className="form-group col-12">
										<label for="exampleInputEmail1">Password *</label>
										<input type={passwordShown ? 'text' : 'password'} className={`form-control ${validationError.passwordError ? 'is-invalid' : ''}`} id="password" name="password" autocomplete="off" placeholder="Enter password" onChange={inputLoginHandler} onKeyUp={handleKeyUp}  />
										<span className="eye" onClick={togglePassword}>
											  {passwordShown ? (
												<i class="fa-regular fa-eye-slash" title="Hide password"></i>
											  ) : (
												<i class="fa-regular fa-eye" title="Show password"></i>
											  )}
										</span>								
										
									</div>
									<input className="checklabel" type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
									<label  for="vehicle1">&nbsp; Remember me</label><br />
									<Link to={config.baseUrl + 'forget-password'} className="forgot"> Click here if you forgotten your password?</Link>									
									<div className="login-button">
										<button className="login-btn" onClick={SubmitForm}>LOGIN</button>
									</div>
								</div>
							</div>
						</div>
					<div className="col-md-7">
						<div className="registration-heading">
							<h2>DON'T HAVE A LOGIN? REGISTER BELOW</h2>
							<p>Registration available for businesses trade account, must have a valid ABN.</p>
							<div className="form-section section-register">
								<div className="form-row"> 
									<div className="form-group col-md-6">
										<label for="first_name">First Name<span className="req-star">*</span></label>
										<input type="text" className={`form-control ${reg1validationError.first_nameError ? 'is-invalid' : ''}`}  id="first_name" name="first_name"  placeholder="Enter first name" onChange={inputRegHandler}  value={regform.first_name}/>
									</div>
									<div className="form-group col-md-6">
										<label for="last_name">Last Name<span className="req-star">*</span></label>
										<input type="text" className={`form-control ${reg1validationError.last_nameError ? 'is-invalid' : ''}`} id="last_name" name="last_name" placeholder="Enter last name"  onChange={inputRegHandler}  value={regform.last_name}/>
									</div>
								</div>
								<div className="form-row"> 
								<div className="form-group col-md-6">
									<label for="email">Email Address*</label>
									<input type="email" className={`form-control ${reg1validationError.emailError ? 'is-invalid' : ''}`} id="email" name="email" placeholder="Enter email address" onChange={inputRegHandler} value={regform.email} />
								</div>
								<div className="form-group col-md-6">
									<label for="email">Accounts Email Address</label>
									<input type="email" className="form-control" id="accounts_email_address" name="accounts_email_address" placeholder="Enter accounts email address" onChange={inputRegHandler} value={regform.accounts_email_address} />
								</div>
								<div className="form-group col-md-12 text-right">
									<input className="checklabel" type="checkbox" id="send_emails_to_both_emails" name="send_emails_to_both_emails" value="1" onChange={handleinvoiceCheckboxChange}/>
									<label for="vehicle1">&nbsp; Send invoices to both emails </label>
								</div>
								</div>
								<div className="form-row">
								<div className="form-group col-md-12">
									<label for="phone">Phone *</label>								
									<input 
										type='text' 
										className={`form-control ${reg1validationError.phoneError ? 'is-invalid' : ''}`} 
										name='phone' 
										id='phone' 
										value={regform.phone} 
										onChange={inputRegHandler} 
										onKeyDown={handleKeyDown}
										maxLength="10"
										placeholder="Enter phone number" />
								</div>
								</div>
								
							
							<div className="form-row">
									<div className="form-group col-md-6" style={{ position: "relative", marginBottom: "20px" }}>
									<label for="password">Password *</label>
										<input type="password" className={`form-control ${reg1validationError.passwordError ? 'is-invalid' : ''}`} id="password" placeholder="Enter Password" name="password" onChange={handlePasswordChange} value={regform.password} onFocus={() => setShowInfoBox(true)} onBlur={() => setShowInfoBox(false)} autocomplete="off"/>
										 {showInfoBox && (
										<div className="validationBoxStyle">
										  <ul style={{ margin: 0, paddingLeft: "15px", listStyle: "none" }}>
											<li style={getValidationStyle(passwordValidation.minLength)}>
											  {passwordValidation.minLength
												? "✓ At least 6 characters"
												: "✗ At least 6 characters"}
											</li>
											<li style={getValidationStyle(passwordValidation.hasUppsymb)}>
											  {passwordValidation.hasUppsymb
												? "✓ Uppercase letter (A-Z) OR A symbol (e.g. @, $, %, !)"
												: "✗ Uppercase letter (A-Z) OR A symbol (e.g. @, $, %, !)"}
											</li>
										  </ul>
									  </div>
									    )}
									</div>
								<div className="form-group col-md-6">
									<label for="password">Confirm Password *</label>
									<input type="password" className={`form-control ${reg1validationError.cpasswordError ? 'is-invalid' : ''}`} id="cpassword" name="cpassword" placeholder="Confirm Password" onChange={inputRegHandler} value={regform.cpassword} />
								</div>
							</div>
							<div className="form-row"> 
							<div className="form-group col-md-6">
								<label for="company">Company name*</label>
								<input type="text" className={`form-control ${reg1validationError.companyError ? 'is-invalid' : ''}`} id="company" name="company" placeholder="Enter company name" onChange={inputRegHandler} value={regform.company} />
							</div>
							<div className="form-group col-md-6">
								<label for="industry">Industry *</label>
								<select className={`form-control ${reg1validationError.industryError ? 'is-invalid' : ''}`} id="industry" name="industry" onChange={inputRegHandler} value={regform.industry}>
								<option value="">Select Industry</option>
								{industry.length > 0 ? (
									industry.map((ind) => (
										<option key={ind.id} value={ind.id}>{ind.title}</option>
									))
								) : null}  
								</select>
							</div>
						</div>
					</div>
						<div className="login-button">
						<button className="login-btn" onClick={ShowNext}>{spinLoader==0 ? 'REGISTER' : 'REGISTERING...' }</button>
						</div>
					</div>
					</div>
					</div>
					)
				:
				(<div className="row">    
					<div className="col-md-12">
						<div className="form-title-box-center">
							<div className="registration-heading">
								<h2>CREATE AN ACCOUNT</h2>
								<p>Register now for a chance to gain early access to exclusive features and content. Be among the first to explore new opportunities in our platform, designed to enhance your experience. Secure your spot today and stay ahead of the curve!</p>
						<div className="form-section section-register">
							<div className="form-group">
								<label for="address">Address *</label>
								<input type="text" className={`form-control ${regvalidationError.addressError ? 'is-invalid' : ''}`} id="address" name="address" placeholder="Enter address" onChange={inputRegHandler} value={regform.address}/>
							</div>
							<div className="form-row"> 
								<div className="form-group col-md-4">
								<label for="exampleInputEmail1">Suburb *</label>
									<input type="text" className={`form-control ${regvalidationError.suburbError ? 'is-invalid' : ''}`} id="suburb" name="suburb" placeholder="Enter suburb" onChange={(e) => SearchSuburb(e.target.value)} autocomplete="off" value={suburb} />
									{SuburbsearchResults.length > 0 ? (
										<ul className="suburbList">
											{SuburbsearchResults.map((row) => (
												<li key={row.id} onClick={() => handleSelectSuburb(row)}>
													{row.rowlocation} 
												</li>
											))}
										</ul>
									) : null}
								</div>
								<div className="form-group col-md-4">
									<label for="exampleInputEmail1">State *</label>
									<select className={`form-control ${regvalidationError.stateError ? 'is-invalid' : ''}`} id="state" name="state" value={regform.state} disabled readonly>
									  <option>Select</option>
									  <option value="ACT">Australian Capital Territory</option>
									  <option value="NSW">New South Wales</option>
									  <option value="NT">Northern Territory</option>
									  <option value="QLD">Queensland</option>
									  <option value="SA">South Australia</option>
									  <option value="TSA">Tasmania</option>
									  <option value="VIC">Victoria</option>
									  <option value="WA">Western Australia</option>
									</select>	
								</div>
								<div className="form-group col-md-4">
									<label for="postcode">Postcode *</label>
									<input type="postcode" value={postcode} className={`form-control ${regvalidationError.postcodeError ? 'is-invalid' : ''}`} id="postcode" name="postcode" placeholder="Enter postcode" readonly disabled />
								</div>
								<div className="form-group col-md-6">
								<label for="abn">ABN (Don’t know the ABN?<a href="#"> Click here </a>to find it) *</label>
									<input type="text" className={`form-control ${regvalidationError.abnError ? 'is-invalid' : ''}`} id="abn" name="abn" placeholder="Enter ABN number" onChange={inputRegHandler} value={regform.abn}/>
								</div>
								<div class="form-group col-md-6">
									<label for="exampleInputEmail1">Average Monthly Spend*</label>
									<select className={`form-control ${regvalidationError.monthly_spendError ? 'is-invalid' : ''}`} id="monthly_spend" name="monthly_spend" onChange={inputmonthly_spendHandler} value={regform.monthly_spend}>
									  <option value="">Select average monthly spend</option>
									  {monthly_spend.length > 0 ? (
											monthly_spend.map((ms) => (
												<option key={ms.id} value={ms.id}>{ms.title}</option>
											))
										) : null} 
									</select>
								</div>
								{showcod==1 ?
								  (
								  <div className="col-md-6 mb-3">
										<label className="form-label" for="is_cod">Would you like to be on a COD or a 30 Day EOM Credit Account?<span className="req-star">*</span></label>
										<select className={validationError.is_codError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="is_cod" name="is_cod" onChange={inputRegHandler} value={regform.is_cod}>
											  <option value="">Select</option>
											  <option value="1">COD</option>
											  <option value="2">30 Day EOM Credit Account</option>				
											</select>
											<div className="invalid-feedback">{validationError.is_codError}</div>
									  </div>
								  ):''				  
								  
								  }
							</div>
							<div className="block-title">
								<p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <a href="#">privacy policy.</a></p>
								<input className="checklabel" type="checkbox" id="newsletter" name="newsletter" value="1" onChange={handlenewsletterCheckboxChange} />
								<label for="vehicle1">&nbsp; I would like to receive sales and trend newsletter updates</label><br />
								<input className="checklabel" type="checkbox" id="terms" name="terms" value="1" onChange={handletermsCheckboxChange}/>
								<label for="vehicle1">&nbsp; I have read and agree to the website <a rel="noopener noreferrer" target="_blank" href="https://fastenerwarehouse.com.au/privacy-policy/">terms and conditions *</a></label>
							</div>
						
						</div>
						  <div className="login-button">
							 <button className="login-btn previous" onClick={ShowPrev}>Previous</button>&nbsp;&nbsp;
							<button className="login-btn" onClick={SubmitRegForm}>{spinLoader==0 ? 'REGISTER' : 'REGISTERING...' }</button>
						  </div>
					    </div>
					</div>
				</div>
				</div>
				)
			  }
        
       
				
				</div>
			</div>
		</div>

		<Footer />
		</>
		)
}

export default Login;
